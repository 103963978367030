import React from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import contact_us from "../img/contact-us.png";

const ContactUs = () => {
  const { t } = useTranslation();

  const Section = ({ children, className = "" }) => {
    return <div className={`mb-8 ${className}`}>{children}</div>;
  };

  const SubSectionTitle = ({ children }) => (
    <div className="mb-4">
      <div className="flex items-center justify-center gap-4">
        <h3 className="font-bold text-xl text-gray-800">{children}</h3>
      </div>
    </div>
  );

  const SubSectionContent = ({ children, className = "" }) => (
    <div className="mb-4">
      <p className={`text-gray-600 text-center ${className}`}>{children}</p>
    </div>
  );

  return (
    <div className="min-h-screen w-[98%] md:w-[90%] xl:w-[85%] mx-auto py-8 ">
      <Helmet>
        <title> {t("contact_us.heading_1")} - Loko Property</title>
        <meta
          name="description"
          content="This is a demo page for the Contact Us section."
        />
      </Helmet>

      <div className="mx-auto px-4 py-12 md:px-6 lg:px-8 bg-white rounded-lg  ">
        {/* Hero Section */}
        <div className="text-center mb-8">
          <h1 className="lg:text-4xl text-2xl font-bold text-gray-900">
            <Trans i18nKey="contact_us.heading_1" />
          </h1>
          <div className="h-1 w-20 bg-blue-500 mx-auto rounded-full mt-6 mb-8"></div>
          <img
            src={contact_us}
            className="w-[70%] lg:w-1/5 block mx-auto mb-6"
            alt="Contact Us"
          />
        </div>

        {/* Main Content Section */}
        <Section>
          <SubSectionContent className="lg:text-center text-lg">
            <Trans
              i18nKey="contact_us.sub_heading_1"
              components={{
                faqLink: (
                  <a
                    className="text-blue-500"
                    href="https://lokoproperty.com/Faq"
                  ></a>
                ),
                staySafeLink: (
                  <a
                    className="text-blue-500"
                    href="https://lokoproperty.com/Stay-Safe"
                  ></a>
                ),
              }}
            />
          </SubSectionContent>

          <SubSectionContent className="lg:text-center text-lg mb-8">
            <Trans i18nKey="contact_us.sub_heading_1_2" />
          </SubSectionContent>

          <SubSectionTitle>
            <Trans i18nKey="contact_us.heading_2" />
          </SubSectionTitle>
          <SubSectionContent className="text-lg mb-8">
            <Trans i18nKey="contact_us.sub_heading_2" />
          </SubSectionContent>

          {/* Contact Methods */}
          <div className="lg:flex justify-evenly gap-8 mt-8">
            {/* Phone Section */}
            <div className="flex-1 text-center mb-6 lg:mb-0">
              <div className="flex justify-center items-center gap-2 mb-3">
                <FaPhoneAlt className="h-5 w-5 text-blue-500" />
                <h3 className="text-lg font-bold text-gray-800">
                  <Trans i18nKey="contact_us.call_us" />
                </h3>
              </div>
              <a
                href="tel:+8809647263753"
                className="text-blue-500 font-semibold text-lg hover:text-blue-600 transition-colors"
              >
                +8809647263753
              </a>
            </div>

            {/* Email Section */}
            <div className="flex-1 text-center">
              <div className="flex justify-center items-center gap-2 mb-3">
                <FaEnvelope className="h-5 w-5 text-blue-500" />
                <h3 className="text-lg font-bold text-gray-800">
                  <Trans i18nKey="contact_us.email_us" />
                </h3>
              </div>
              <a
                href="mailto:support@lokoproperty.com"
                className="text-blue-500 font-semibold text-lg hover:text-blue-600 transition-colors"
              >
                support@lokoproperty.com
              </a>
            </div>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default ContactUs;
