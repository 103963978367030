import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../context/UserContext";
import { toast } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import { Helmet } from "react-helmet";

//icons
import copy from "../../../svg/copy.svg";
import Logo from "../../../img/icn/Logo.png";
import star from "../../../img/icn/star.png";
import check from "../../../img/icn/check.png";
import group from "../../../img/icn/group.png";
import call from "../../../img/icn/call.png";
import save from "../../../img/icn/love.png";
import unsave from "../../../img/icn/unsave.png";
import share from "../../../img/icn/share.png";
import loc from "../../../img/icn/location.png";
import bed from "../../../img/icn/bed.png";
import bath from "../../../img/icn/bath.png";
import area from "../../../img/icn/area.png";
import garage from "../../../img/icn/garage.png";

import PostLoading from "../../../components/LocalUi/PostLoading";

const GroupWiseBuildingDetails = () => {
  const { id } = useParams(); // Get the product ID from the URL
  const [propertyData, setPropertyData] = useState(null);
  const [propertyRenterData, setPropertyRenterData] = useState(null);
  const [propertyImages, setPropertyImages] = useState([]); // Initialize as an empty array
  const [img, setImg] = useState(null); // Initialize as null
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [loading, setLoading] = useState(false);

  const currentUser = localStorage.getItem("userToken");
  const location = useLocation();
  const navigate = useNavigate();

  ///Language
  const { t, i18n } = useTranslation();



  //get current user data
  const { user, error, isLoading } = useUser();
  const userToken = localStorage.getItem("userToken");

  //get user saved post
  const [savedPosts, setSavedPosts] = useState([]);
  const userID = user?.id; // Use optional chaining to prevent errors if user is undefined

  //get user SavePost
  useEffect(() => {
    // Check if user exists before making the Axios request
    if (user) {
      const handleFetchSavedPosts = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/profile/getSavePost`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`, // Ensure userToken is defined
              },
            }
          );
          setSavedPosts(response.data.SavedPosts);
        } catch (error) {
          console.error("Error making Axios request:", error);
        }
      };

      handleFetchSavedPosts();
    }
  }, [user]); // Run when the user changes

  //popup modal`
  const [contactModal, setContactModal] = useState(false);
  const openModal = () => {
    setContactModal(true);
  };

  const closeModal = () => {
    setContactModal(false);
  };

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(propertyData.number);
    toast.success("Number copied to clipboard!");
    setContactModal(false);
  };

  //popup Intersted modal
  const [intsertedModal, setIntsertedModal] = useState(false);
  const openInterstedModal = () => {
    setIntsertedModal(true);
  };

  const closeInterstedModal = () => {
    setUserName("");
    setUserOccupation("");
    setUserNumber("");
    setIntsertedModal(false);
  };

  useEffect(() => {
    // Define the URL with the dynamic ID
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/group_building_details/${id}`;
    // Make the Axios GET request
    axios
      .get(apiUrl)
      .then((response) => {
        // Set the retrieved data in the state
        setPropertyData(response.data.groupBuildingPost);
        setPropertyImages(response.data.groupBuildingPost.imageUrls);
        setPropertyRenterData(response.data.admin);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]); // Re-run the effect when the ID changes

  useEffect(() => {
    const interval = setInterval(() => {
      // Change image every 10 seconds
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % propertyImages.length
      );
    }, 10000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [propertyImages.length]);

  const changeImage = (direction) => {
    // Change image based on direction (prev or next)
    if (direction === "prev") {
      setCurrentImageIndex(
        (prevIndex) =>
          (prevIndex - 1 + propertyImages.length) % propertyImages.length
      );
    } else {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % propertyImages.length
      );
    }
  };

  const handleThumbnailClick = (index) => {
    // Handle thumbnail click to change main image
    setCurrentImageIndex(index);
  };

  //Save Un-save Optimistic UI Update
  const handleSaveUnSavePost = async (event, postId) => {
    event.stopPropagation();

    if (!currentUser) {
      localStorage.setItem("redirectUrl", location.pathname);
      toast.error("You have to login first");
      navigate("/SignIn");
    } else {
      const userID = user.id;
      const PostID = postId;
      const isPostSaved = savedPosts.some(
        (savedPost) => savedPost._id === PostID
      );

      try {
        if (isPostSaved) {
          // Optimistically update the UI
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
          toast.success("Post Unsaved");

          // Make the network request in the background
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/profile/deleteUserSavedPost/${PostID}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
        } else {
          // Optimistically update the UI
          const newSavedPost = { _id: PostID }; // Create a minimal representation of the saved post
          setSavedPosts((prevPosts) => [...prevPosts, newSavedPost]);
          toast.success("Post Saved");

          // Make the network request in the background
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/profile/setSavePost`,
            { userID, PostID },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          // Update the UI with the full post details from the server response
          const savedPost = response.data.SavedPosts.find(
            (post) => post._id === PostID
          );
          setSavedPosts((prevPosts) =>
            prevPosts.map((post) => (post._id === PostID ? savedPost : post))
          );
        }
      } catch (error) {
        // Revert the optimistic UI update on error
        if (isPostSaved) {
          setSavedPosts((prevPosts) => [...prevPosts, { _id: PostID }]);
        } else {
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
        }

        console.error("Error saving/unsaving post:", error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.error;
          console.error("Server error message:", errorMessage);
          //toast.error(`Error: ${errorMessage}`);
        } else {
          //toast.error("Error saving/unsaving post");
        }
      }
    }
  };

  //random user interaction
  //send to post collection comments
  const [userName, setUserName] = useState("");
  const [userOccupation, setUserOccupation] = useState("");
  const [userNumber, setUserNumber] = useState("");

  const handleCommentSubmit = async (e) => {
    setIntsertedModal(false);
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/group_set_comments`,
        {
          postId: id,
          userName: userName,
          userOccupation: userOccupation,
          userNumber: userNumber,
        }
      );
      const message = response.data.message;
      toast.success(message);
      setUserName("");
      setUserOccupation("");
      setUserNumber("");

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;
        setUserName("");
        setUserNumber("");
        setUserOccupation("");
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
      setLoading(false);
    }
  };

  const hasOtherFacility =
    propertyData &&
    Object.keys(propertyData).some(
      (key) =>
        [
          "mosque",
          "security",
          "fireExit",
          "fireProtection",
          "securityAlarm",
          "electronicSecurity",
          "conversation",
          "wasaConnection",
          "selfWaterSupply",
          "hotWater",
          "cylinderGas",
          "gymnasium",
          "pool",
          "garden",
          "generator",
          "intercom",
          "wifiConnection",
          "satelliteCableTv",
          "solarPanel",
          "servantRoom",
          "guestParking",
        ].includes(key) && propertyData[key] === true
    );

  function getRelativeTime(postDate) {
    const post = new Date(postDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now - post) / 1000);

    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  }

  const sanitizeHTML = (html) => ({
    __html: DOMPurify.sanitize(html.replace(/\n/g, "<br>")),
  });

  return (
    <div className={`   `}>
      <Helmet>
        <title>Property Details - Loko Property</title>
      </Helmet>
      {propertyData ? (
        <div>
          {/* Image slider section is here*/}
          <div
            className={`mt-4 w-full flex justify-center items-center duration-200`}
          >
            <div className="w-[98%] md:w-[90%] xl:w-[85%] duration-200 ">
              <div className="image-slider-container w-full bg-slate-50 p-5 rounded-2xl overflow-hidden">

                <div className="main-image-container relative overflow-hidden">
                  <div className="absolute bg-cover blur-md  scale-[5]">
                    <img
                      src={propertyImages[currentImageIndex]}
                      className="m-auto block bg-cover "
                    />
                  </div>

                  <img
                    className="m-auto block 2xl:h-[500px] xl:h-[400px] h-[300px] rounded-xl object-contain relative"
                    src={propertyImages[currentImageIndex]}
                    alt={`property-${currentImageIndex}`}
                  />

                  <div className="h-[40px] flex justify-between w-full top-0 absolute duration-200 ">
                    {propertyData.type === "group" ? (
                      <div>
                        <img src={group} alt="camera" className="h-24 w-24" />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={group}
                          alt="camera"
                          className="h-[85px] w-[85px]"
                        />
                      </div>
                    )}
                  </div>

                  {propertyImages.length > 1 && (
                    <>
                      <div className="absolute top-1/2 left-0 transform -translate-y-1/2 z-20">
                        <button
                          className="h-8 w-5 hover:w-10 duration-200 text-center items-center bg-[#0000008f] text-white font-bold rounded-[100%]"
                          onClick={() => changeImage("prev")}
                        >
                          ◀
                        </button>
                      </div>

                      <div className="absolute top-1/2 right-0 transform -translate-y-1/2 z-20">
                        <button
                          className="h-8 w-5 hover:w-10 duration-200 text-center items-center bg-[#0000008f] text-white font-bold rounded-[100%]"
                          onClick={() => changeImage("next")}
                        >
                          ▶
                        </button>
                      </div>
                    </>
                  )}

                  <div className="lg:px-3 px-1 h-[50px] w-full m-auto bottom-0 flex absolute bg-gradient-to-b from-transparent to-[#000000] duration-200 z-20">
                    {propertyRenterData.proPic ? (
                      <img
                        src={propertyRenterData.proPic}
                        alt="User Profile"
                        className="lg:h-10 h-8 lg:w-10 w-8 rounded-full"
                      />
                    ) : (
                      <img
                        src={Logo}
                        alt="User Profile"
                        className="lg:h-10 h-8 lg:w-10 w-8 rounded-full"
                      />
                    )}

                    <h2 className="text-white p-2 lg:text-base text-xs ">
                      {"Loko Property"}
                    </h2>

                    <h2 className="text-white p-2 lg:text-base text-xs">
                      {getRelativeTime(propertyData.postDate)}
                    </h2>
                  </div>
                </div>

                <div className="thumbnail-container mt-3 flex justify-between items-center ">
                  <div>
                    {propertyImages.map((image, index) => (
                      <button
                        className="focus:outline-none"
                        key={index}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <img
                          className="mr-2 block h-10 lg:h-12 xl:h-16 2xl:h-20 w-16 lg:w-20 xl:w-28 2xl:w-36 rounded-md duration-200 hover:translate-y-[-3px] ease-in-out hover:shadow-xl"
                          src={image}
                          alt={`thumbnail-${index}`}
                        />
                      </button>
                    ))}
                  </div>

                  <div className="md:flex md:space-y-0 space-y-1 justify-center items-center md:space-x-1 duration-300 ">

                    <div>
                      <div
                        onClick={openInterstedModal}
                        className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[140px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-red-200 hover:shadow-lg duration-200"
                      >
                        <img
                          src={star}
                          className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                          alt="name"
                        />
                        <div className="lg:text-[16px] hidden lg:block ">
                          {"Book Now"}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        onClick={openModal}
                        className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[110px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-red-200 hover:shadow-lg duration-200"
                      >
                        <img
                          src={call}
                          className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                          alt="name"
                        />

                        <div className="lg:text-[16px] hidden lg:block ">
                          {"Contact"}
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={(event) =>
                        handleSaveUnSavePost(event, propertyData._id)
                      }
                    >
                      <div className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[100px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-red-200 hover:shadow-lg duration-200">
                        {savedPosts.some(
                          (savedPost) => savedPost._id === propertyData._id
                        ) ? (
                          <div className="flex lg:space-x-2">
                            <img
                              src={save}
                              className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                              alt="Filled Love Icon"
                            />
                            <div className="lg:text-[16px] hidden lg:block">
                              {"Saved"}
                            </div>
                          </div>
                        ) : (
                          <div className="flex lg:space-x-2">
                            <img
                              src={unsave}
                              className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                              alt="Empty Love Icon"
                            />
                            <div className="lg:text-[16px] hidden lg:block">
                              {"Save"}
                            </div>
                          </div>
                        )}
                      </div>
                    </button>

                    <div className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[100px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-green-200 hover:shadow-lg duration-200">
                      <img
                        src={share}
                        className="lg:h-6 h-5 lg:w-6 w-6 duration-200"
                        alt="name"
                      />
                      <div className="lg:text-[16px] hidden lg:block ">
                        {"Share"}
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

          {/* Display property details here using propertyData */}
          <div
            className={` w-full flex  justify-center items-center duration-200  `}
          >
            <div className="w-[98%] md:w-[90%] xl:w-[85%]  duration-200  ">
              <div className="w-full bg-slate-100 lg:p-5 p-3 rounded-2xl mt-4">
                <div className="Title font-bold lg:text-3xl text-xl">
                  <p>{propertyData.postTitle}</p>
                </div>

                <div className=" grid  grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-2">
                  {/*Total Price*/}
                  <div className="Price flex items-center rounded-lg font-bold lg:text-base text-xs   mt-4 lg:h-14 h-10 px-2 bg-white">
                    <span className="">{"Total Price :\u00A0"}</span>
                    <span className="text-green-700 ">
                      {propertyData.price} Tk.
                    </span>
                    <span className="lg:text-base text-xs">{"\u00A0 (approximate)"} </span>
                  </div>

                  {/*Land Price*/}
                  <div className="Price flex items-center  rounded-lg font-bold lg:text-base text-xs  mt-4 lg:h-14 h-10 px-2 bg-white">
                    <span className="">{"Land Price :\u00A0"} </span>
                    <span className="text-green-700 ">
                      {propertyData.landPrice} Tk.
                    </span>
                    <span className=" lg:text-base text-xs">{"\u00A0 (approximate) "} </span>
                  </div>

                  {/*location*/}
                  <div className="division flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={loc} alt="" className="h-5 w-5 mr-2"></img>

                    <p className="lg:text-base text-sm">
                      {propertyData.subDistrict}, {propertyData.district},{" "}
                      {propertyData.division}{" "}
                    </p>
                  </div>

                  <div className="Bedroom flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={bed} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {propertyData.bedroom}
                      {" Bedrooms"}
                    </p>
                  </div>

                  <div className="Bathroom flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={bath} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {propertyData.bathroom}
                      {" Bathrooms"}
                    </p>
                  </div>

                  <div className="Bathroom flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {propertyData.balconies}
                      {" Balconies"}
                    </p>
                  </div>

                  {propertyData.garages ? (
                    <div className="Garage flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                      <img src={garage} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Garage :"} {propertyData.garages}
                      </p>
                    </div>
                  ) : null}

                  <div className="size flex items-center rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Flat Size "}
                      {propertyData.size}
                      {" ft²"}
                    </p>
                  </div>

                  <div className="landSize flex items-center rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Land Size "}
                      {propertyData.landSize}
                      {" Katha"}
                    </p>
                  </div>
                </div>

                <div className="mt-10">
                  <h2 className="2xl:text-3xl xl:text-xl text-lg font-bold text-gray-500">
                    Description
                  </h2>

                  <div
                    className="mt-4 "
                    dangerouslySetInnerHTML={sanitizeHTML(
                      propertyData.postDetails
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {/*Property Specifications*/}
          <div
            className={` w-full flex justify-center items-center duration-200  `}
          >
            <div className="  w-[98%] md:w-[90%] xl:w-[85%]  duration-200  ">
              <div className="mt-4 bg-slate-100 p-5 rounded-2xl">
                <p className="font-bold 2xl:text-3xl xl:text-xl text-lg text-gray-500">
                  Property Specifications
                </p>

                <div className=" grid  xl:grid-cols-5 grid-cols-2 lg:grid-cols-4 gap-2 ">
                  {/* Construction Status*/}
                  <div className=" flex items-center rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-fuchsia-100">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Construction Status: "}
                      {propertyData.construction}
                      {""}
                    </p>
                  </div>

                  {/* Condition*/}
                  <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-purple-200">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Condition : "}
                      {propertyData.condition}
                    </p>
                  </div>

                  {/* Bedroom*/}
                  {propertyData.bedroom ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-violet-100">
                      <img src={bed} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Bedrooms : "}
                        {propertyData.bedroom}
                      </p>
                    </div>
                  ) : null}

                  {/* Bathroom*/}
                  {propertyData.bathroom ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-indigo-100">
                      <img src={bath} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Bathrooms : "}
                        {propertyData.bathroom}
                      </p>
                    </div>
                  ) : null}

                  {/* Balconies*/}
                  {propertyData.balconies ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-emerald-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Balconies : "}
                        {propertyData.balconies}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Lift*/}
                  {propertyData.lift ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-yellow-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Lift : "}
                        {propertyData.lift}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Drawing rooms*/}
                  {propertyData.drawingRooms ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-amber-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Drawing Rooms : "}
                        {propertyData.drawingRooms}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Garages*/}
                  {propertyData.garages ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-cyan-200">
                      <img src={garage} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Garage : "}
                        {propertyData.garages}
                      </p>
                    </div>
                  ) : null}

                  {/* Furnishing*/}
                  {propertyData.furnishing ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-purple-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Furnishing : "}
                        {propertyData.furnishing}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Facing*/}
                  {propertyData.facing ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-orange-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Facing : "}
                        {propertyData.facing}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Total Unit*/}
                  {propertyData.unit ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-blue-200">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Unit : "}
                        {propertyData.unit}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Total floor*/}
                  {propertyData.totalFloor ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-green-200">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Total Floor : "}
                        {propertyData.totalFloor}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Total available floor*/}
                  {propertyData.availableFloor ? (
                    <div
                      className={`flex items-center rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-red-200 relative ${
                        propertyData.availableFloor.split(",").length > 5
                          ? "group"
                          : ""
                      }`}
                      title={
                        propertyData.availableFloor.split(",").length > 5
                          ? propertyData.availableFloor
                          : ""
                      }
                    >
                      <img src={area} alt="" className="h-5 w-5 mr-2" />
                      <p className="text-black lg:text-base text-xs truncate">
                        {"Available Floor : "}
                        {propertyData.availableFloor.split(",").length > 5
                          ? propertyData.availableFloor
                              .split(",")
                              .slice(0, 5)
                              .join(",") + "..."
                          : propertyData.availableFloor}
                      </p>
                      {propertyData.availableFloor.split(",").length > 5 && (
                        <div className="absolute left-0 bottom-full mt-2 p-2 bg-red-200 shadow-lg rounded-lg hidden group-hover:block z-10">
                          {propertyData.availableFloor}
                        </div>
                      )}
                    </div>
                  ) : null}

                  <div className="size flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Flat Size :"}
                      {propertyData.size}
                      {" ft²"}
                    </p>
                  </div>

                  <div className="landSize flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Land Size :"}
                      {propertyData.landSize}
                      {" Katha"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Other facility 2.0*/}
          <div
            className={` w-full flex justify-center items-center duration-200  `}
          >
            <div className="w-[98%] md:w-[90%] xl:w-[85%]  duration-200 ">
              <div className="mt-3 bg-slate-100 p-5  rounded-2xl">
                <div className="">
                  <p className="2xl:text-3xl xl:text-xl text-lg font-bold text-gray-500">
                    {" "}
                    {hasOtherFacility ? <div>Others facility</div> : null}
                  </p>

                  <div className="grid  xl:grid-cols-5 grid-cols-2 lg:grid-cols-4 gap-2 ">
                    {propertyData.mosque === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Mosque"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Mosque
                        </p>
                      </div>
                    ) : null}

                    {propertyData.security === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="security"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Security
                        </p>
                      </div>
                    ) : null}

                    {propertyData.fireExit === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="fireExit"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Fire Exit
                        </p>
                      </div>
                    ) : null}

                    {propertyData.fireProtection === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="fireProtection"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Fire protection
                        </p>
                      </div>
                    ) : null}

                    {propertyData.securityAlarm === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt=" Security Alarm system"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          {" "}
                          Security Alarm system
                        </p>
                      </div>
                    ) : null}

                    {propertyData.electronicSecurity === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Electronic security"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Electronic security
                        </p>
                      </div>
                    ) : null}

                    {propertyData.conversation === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Conversation Room"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Conversation Room
                        </p>
                      </div>
                    ) : null}

                    {propertyData.wasaConnection === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt=" Wasa connection"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          {" "}
                          Wasa connection
                        </p>
                      </div>
                    ) : null}

                    {propertyData.hotWater === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Hot water"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Hot water
                        </p>
                      </div>
                    ) : null}

                    {propertyData.selfWaterSupply === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="selfWaterSupply"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Self Water Supply
                        </p>
                      </div>
                    ) : null}

                    {propertyData.cylinderGas === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Cylinder gas"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Cylinder gas
                        </p>
                      </div>
                    ) : null}

                    {propertyData.gymnasium === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Gymnasium"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Gymnasium
                        </p>
                      </div>
                    ) : null}

                    {propertyData.pool === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Pool"
                        ></img>
                        <p className="text-black lg:text-base text-xs">Pool</p>
                      </div>
                    ) : null}

                    {propertyData.garden === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Garden"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Garden
                        </p>
                      </div>
                    ) : null}

                    {propertyData.generator === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Generator"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Generator
                        </p>
                      </div>
                    ) : null}

                    {propertyData.intercom === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Intercom"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Intercom
                        </p>
                      </div>
                    ) : null}

                    {propertyData.wifiConnection === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Wi-Fi connection"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Wi-Fi connection
                        </p>
                      </div>
                    ) : null}

                    {propertyData.satelliteCableTv === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt=" Satellite or cable TV"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          {" "}
                          Satellite or cable TV
                        </p>
                      </div>
                    ) : null}

                    {propertyData.solarPanel === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Solar panel"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Solar panel
                        </p>
                      </div>
                    ) : null}

                    {propertyData.servantRoom === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Servant room"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Servant room
                        </p>
                      </div>
                    ) : null}

                    {propertyData.guestParking === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md  mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Guest parking"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Guest parking
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* User interaction*/}

          {intsertedModal ? (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white p-5 rounded-2xl shadow-md ">
                <div
                  className={` w-full flex justify-center  items-center duration-200 `}
                >
                  <form
                    className=" bg-slate-100  text-center p-5 rounded-2xl"
                    onSubmit={handleCommentSubmit}
                  >
                    <div className="mt-10">
                      <p className="lg:text-xl text-xl text-gray-500 font-bold">
                        Enter Your name
                      </p>
                      <input
                        type="text"
                        required
                        value={userName}
                        placeholder="Name"
                        onChange={(e) => setUserName(e.target.value)}
                        className="border-2 mt-3 h-8 w-[250px] rounded-lg border-gray-300 px-1 pl-2"
                      ></input>
                    </div>

                    <div className="mt-10">
                      <p className="lg:text-xl text-xl text-gray-500 font-bold">
                        Enter Occupation
                      </p>
                      <input
                        type="text"
                        required
                        value={userOccupation}
                        placeholder="Occupation"
                        onChange={(e) => setUserOccupation(e.target.value)}
                        className="border-2 mt-3 h-8 w-[250px] rounded-lg border-gray-300 px-1 pl-2"
                      ></input>
                    </div>

                    <div className="mt-10  ">
                      <p className="lg:text-xl text-xl text-gray-500 font-bold">
                        Enter your phone number
                      </p>
                      <input
                        required
                        className="border-2 mt-3 h-8  w-[250px] rounded-lg border-gray-300 px-1 pl-2"
                        placeholder="01xxxxxxxx"
                        type="number"
                        value={userNumber}
                        onKeyPress={(e) => {
                          if (e.charCode < 48 || e.charCode > 57) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setUserNumber(e.target.value)}
                      ></input>
                    </div>

                    <div className="flex  justify-end space-x-4 mt-5">
                      <button
                        type="submit"
                        className="w-[120px]  flex space-x-3  items-center justify-center py-2 rounded text-gray-800 bg-slate-400 font-bold hover:text-white hover:bg-slate-500 duration-200"
                      >
                        {loading && (
                          <CgSpinner size={20} className="mt-1 animate-spin" />
                        )}
                        <span className="text-md">Submit</span>
                      </button>

                      <button
                        type="button"
                        onClick={closeInterstedModal}
                        className="bg-red-400 text-white p-2 rounded"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : null}

          {contactModal && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-lg mx-3 p-10 ">
                <h2 className="text-xl font-semibold mb-4">Contact Number</h2>
                <div className="flex items-center space-x-5 ">
                  <img
                    src={call}
                    className="lg:h-12 h-10 lg:w-12 w-10 duration-200"
                    alt="name"
                  />

                  {isMobile() ? (
                    <a
                      href={`tel:${propertyData.number}`}
                      className="text-blue-500"
                    >
                      {propertyData.number}
                    </a>
                  ) : (
                    <div className=" ">
                      <p> আমাদের সাথে যোগাযোগ করুন </p>

                      <div className=" flex">
                        {" "}
                        <span className=" text-blue-600 font-semibold">
                          {propertyData.number}
                        </span>
                        <div className="px-2" onClick={copyToClipboard}>
                          <img src={copy} alt="copy" />
                        </div>{" "}
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-6 flex justify-end">
                  <button
                    className="px-4 py-1 bg-red-300 rounded-md"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-[90vh] flex justify-center">
          <div className="w-[70%]">
            <PostLoading />
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupWiseBuildingDetails;
