import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { useUser } from "../../context/UserContext";
import DOMPurify from "dompurify";
import icn_user from "../../img/icn/icn_user.png";
import {
  MessageSquare,
  Reply,
  Send,
  Trash2,
  ChevronDown,
  ChevronUp,
} from "lucide-react";

import { t } from "i18next";

const CommentSection = ({ postId }) => {
  const { user } = useUser();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [replyContent, setReplyContent] = useState({});
  const [expandedComments, setExpandedComments] = useState({});

  const [visibleReplies, setVisibleReplies] = useState({});
  const [visibleReplyCount, setVisibleReplyCount] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const userToken = localStorage.getItem("userToken");
  const location = useLocation();

  // Fetch comments when component mounts
  useEffect(() => {
    if (!userToken) {
      localStorage.setItem("redirectUrl", location.pathname);
    }

    fetchComments();
  }, [postId]);

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/blog/blogComments/${postId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setComments(response.data.comments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  // Validate comment (no links)
  const validateComment = (text) => {
    // Regex to detect URLs
    const urlRegex = /(https?:\/\/[^\s]+)/gi;
    return !urlRegex.test(text);
  };

  // Initialize visibleReplies to be true by default when comments load
  useEffect(() => {
    if (comments.length > 0) {
      const initialVisibleReplies = comments.reduce((acc, comment) => {
        if (comment.replies && comment.replies.length > 0) {
          acc[comment._id] = true;
        }
        return acc;
      }, {});
      setVisibleReplies(initialVisibleReplies);
    }
  }, [comments]);

  // Add a new comment
  const addComment = async () => {
    // Validate comment
    if (!user) {
      toast.error("Please log in to comment");
      return;
    }

    if (!validateComment(newComment)) {
      toast.error("Comments cannot contain links");
      return;
    }

    if (newComment.trim() === "") {
      toast.error("Comment cannot be empty");
      return;
    }

    if (!newComment.trim()) return toast.error("Comment cannot be empty.");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/blog/addBlogComment/${postId}`,
        { content: newComment },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setNewComment("");
      fetchComments();
    } catch (error) {
      console.error("Error adding comment:", error);
      toast.error("Failed to post comment");
    }
  };

  // Add a reply to a specific comment
  const addReply = async (commentId) => {
    const replyText = replyContent[commentId];

    if (!user) {
      toast.error("Please log in to reply");
      return;
    }

    if (!validateComment(replyText)) {
      toast.error("Replies cannot contain links");
      return;
    }

    if (!replyText || replyText.trim() === "") {
      toast.error("Reply cannot be empty");
      return;
    }

    if (!replyText.trim()) return toast.error("Reply cannot be empty.");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/blog/addBlogReply/${postId}/${commentId}`,
        { content: replyText },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setReplyContent({ ...replyContent, [commentId]: "" });
      fetchComments();
      setExpandedComments((prev) => ({
        ...prev,
        [commentId]: false,
      }));
    } catch (error) {
      console.error("Error adding reply:", error);
      toast.error("Failed to post reply");
    }
  };

  // Toggle comment expansion
  const toggleCommentExpansion = (commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  // Toggle replies visibility
  const toggleRepliesVisibility = (commentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  // Show more replies
  const showMoreReplies = (commentId) => {
    setVisibleReplyCount((prev) => ({
      ...prev,
      [commentId]: (prev[commentId] || 3) + 2,
    }));
  };

  const deleteComment = async () => {
    if (!commentToDelete) return;

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/blog/deleteBlogComment/${postId}/${commentToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setIsDeleteModalOpen(false); // Close modal
      setCommentToDelete(null); // Reset selected comment
      fetchComments(); // Refresh comments after deletion
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const handleDeleteClick = (commentId) => {
    setCommentToDelete(commentId);
    setIsDeleteModalOpen(true);
  };

  const cancelDelete = () => {
    setIsDeleteModalOpen(false);
    setCommentToDelete(null);
  };

  return (
    <div className=" mx-auto bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center mb-4">
        <MessageSquare className="mr-2 text-blue-500" />
        <h2 className="text-xl font-bold text-gray-800">Comments</h2>
      </div>

      {/* New Comment Input */}
      {user ? (
        <div className="mb-6 bg-gray-50 rounded-lg p-4 border border-gray-200">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Share your thoughts..."
            className="w-full p-3 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-200 transition-all duration-300 resize-none"
            rows={4}
          ></textarea>
          <div className="flex justify-end mt-2">
            <button
              onClick={addComment}
              className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
            >
              <Send className="mr-2 h-4 w-4" />
              Post Comment
            </button>
          </div>
        </div>
      ) : (
        <div className="mb-6 bg-gray-50 rounded-lg p-4 border border-gray-200">
          <p className="text-gray-600">
            Please{" "}
            <Link to="/SignIn" className="text-blue-500 hover:text-blue-600">
              {" "}
              log in{" "}
            </Link>{" "}
            to leave a comment.
          </p>
        </div>
      )}

      {/* Comments List */}
      {comments.length === 0 ? (
        <div className="text-center text-gray-500 italic">
          No comments yet. Be the first to comment!
        </div>
      ) : (
        <div className="space-y-4">
          {comments.map((comment) => (
            <div key={comment._id} className="border-b pb-4 last:border-b-0">
              {/* Main Comment */}
              <div className="flex items-start space-x-3">
                <img
                  src={comment.user?.proPic || icn_user}
                  alt="User"
                  className="w-10 h-10 rounded-full object-cover"
                />
                <div className="flex-1">
                  <div className="flex items-center space-x-2">
                    <p className="font-semibold text-gray-800">
                      {comment.user?.username || "Unknown User"}
                    </p>
                    <span className="text-xs text-gray-500">
                      {new Date(comment.createdAt).toLocaleString()}
                    </span>
                  </div>
                  <div className=" flex space-x-2 items-center">
                    <div
                      className="mt-2 bg-gray-100 p-3 rounded-lg text-gray-700"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(comment.content),
                      }}
                    ></div>

                    {/* Delete Button for Main Comment */}
                    {user && comment.user?._id === user?.id && (
                      <button
                        onClick={() => handleDeleteClick(comment._id)}
                        className="text-red-300 hover:text-red-600 text-sm mt-2 transition-colors"
                      >
                        <Trash2 className="mr-1 h-4 w-4" />
                      </button>
                    )}
                  </div>

                  {/* Reply Button */}
                  {user ? (
                    <button
                      onClick={() => toggleCommentExpansion(comment._id)}
                      className="flex items-center text-blue-500 hover:text-blue-600 text-sm mt-2 transition-colors"
                    >
                      <Reply className="mr-1 h-4 w-4" />
                      {expandedComments[comment._id] ? "Cancel" : "Reply"}
                    </button>
                  ) : null}

                  {/* Reply Input */}
                  {expandedComments[comment._id] && (
                    <div className="mt-3 space-y-2">
                      <textarea
                        value={replyContent[comment._id] || ""}
                        onChange={(e) =>
                          setReplyContent({
                            ...replyContent,
                            [comment._id]: e.target.value,
                          })
                        }
                        placeholder="Write your reply..."
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-200 transition-all duration-300 resize-none"
                        rows={3}
                      ></textarea>
                      <div className="flex justify-end">
                        <button
                          onClick={() => addReply(comment._id)}
                          className="flex items-center px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                        >
                          <Send className="mr-1 h-4 w-4" />
                          Send Reply
                        </button>
                      </div>
                    </div>
                  )}

                  {/* Show/Hide Replies */}
                  {comment.replies && comment.replies.length > 0 && (
                    <div className="mt-2">
                      <button
                        onClick={() => toggleRepliesVisibility(comment._id)}
                        className="flex items-center text-blue-500 hover:text-blue-600 text-sm transition-colors"
                      >
                        {visibleReplies[comment._id] ? (
                          <>
                            <ChevronUp className="mr-1 h-4 w-4" />
                            Hide Replies
                          </>
                        ) : (
                          <>
                            <ChevronDown className="mr-1 h-4 w-4" />
                            Show {comment.replies.length} Repl
                            {comment.replies.length > 1 ? "ies" : "y"}
                          </>
                        )}
                      </button>
                    </div>
                  )}

                  {/* Replies */}
                  {comment.replies &&
                    comment.replies.length > 0 &&
                    visibleReplies[comment._id] && (
                      <div className="mt-4 space-y-3 pl-4 border-l-2 border-gray-200">
                        {comment.replies
                          .slice(0, visibleReplyCount[comment._id] || 3)
                          .map((reply) => (
                            <div
                              key={reply._id}
                              className="flex items-start space-x-2"
                            >
                              <img
                                src={reply.user?.proPic || icn_user}
                                alt="User"
                                className="w-8 h-8 rounded-full object-cover"
                              />
                              <div>
                                <div className="flex items-center space-x-2">
                                  <p className="font-medium text-sm text-gray-800">
                                    {reply.user?.username || "Unknown User"}
                                  </p>
                                  <span className="text-xs text-gray-500">
                                    {new Date(reply.createdAt).toLocaleString()}
                                  </span>
                                </div>
                                <div className="flex space-x-2 items-center">
                                  <div
                                    className="mt-1 bg-gray-100 p-2 rounded-lg text-sm text-gray-700"
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(reply.content),
                                    }}
                                  ></div>

                                  {/* Delete Button for Replies */}
                                  {user && reply.user?._id === user?.id && (
                                    <button
                                      onClick={() =>
                                        handleDeleteClick(reply._id)
                                      }
                                      className="text-red-300 hover:text-red-600 text-xs mt-2 transition-colors"
                                    >
                                      <Trash2 className="mr-1 h-4 w-4" />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* Show More Replies Button */}
                        {comment.replies.length >
                          (visibleReplyCount[comment._id] || 3) && (
                          <button
                            onClick={() => showMoreReplies(comment._id)}
                            className="text-blue-500 hover:text-blue-600 text-sm mt-2 transition-colors"
                          >
                            Show{" "}
                            {comment.replies.length -
                              (visibleReplyCount[comment._id] || 3)}{" "}
                            more repl
                            {comment.replies.length -
                              (visibleReplyCount[comment._id] || 3) >
                            1
                              ? "ies"
                              : "y"}
                          </button>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h3 className="text-lg font-semibold text-gray-800">
              Confirm Deletion
            </h3>
            <p className="mt-2 text-gray-600">
              Are you sure you want to delete this comment? This action cannot
              be undone.
            </p>
            <div className="mt-4 flex justify-end space-x-3">
              <button
                onClick={cancelDelete}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={deleteComment}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentSection;
