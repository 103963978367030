import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import StaySafeOnLokoProperty from "../../img/StaySafeonLokoProperty.png";
import GeneralSafetyAdvice from "../../img/GeneralSafetyAdvice.png";
import CommonScamsd from "../../img/CommonScamsandFraudstoAvoid.png";
import SafetyFeatures from "../../img/SafetyFeatures.png";
import reporting from "../../img/reporting.png";
const StaySafe = () => {
  const { t } = useTranslation();

  const headingImages = {
    hero: StaySafeOnLokoProperty,
    heading_2: GeneralSafetyAdvice,
    heading_3: CommonScamsd,
    heading_4: SafetyFeatures,
    heading_5: reporting,
  };

  const Section = ({ headingKey, children, className = "" }) => {
    const heading = t(`stay_safe.${headingKey}`);
    const imagePath = headingImages[headingKey] || "/api/placeholder/60/60";

    return (
      <div className={`mb-8 ${className}`}>
        <div className="flex items-center gap-4 mb-4">
          <img
            src={imagePath}
            alt={`${heading} icon`}
            className="w-12 h-12 rounded-full object-cover"
          />
          <h2 className="text-2xl font-bold text-gray-800">{heading}</h2>
        </div>
        {children}
      </div>
    );
  };

  const SubSectionTitle = ({ title }) => (
    <div className="mb-4 lg:ml-16 ml-2 ">
      <div className="flex items-center gap-4">
        <h3 className="font-semibold text-lg text-gray-700">{title}</h3>
      </div>
    </div>
  );

  const SubSectionContent = ({ content }) => (
    <div className="mb-4 lg:ml-16 ml-4  ">
      <div className=" flex space-x-2 ">
        <p className="">●</p>
        <p className="text-gray-600">{content}</p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen w-[98%] md:w-[90%] xl:w-[75%] py-8 mx-auto ">
      <Helmet>
        <title>{t("stay_safe.title")} - Loko Property</title>
      </Helmet>

      <div className=" mx-auto px-4 py-12 md:px-6 lg:px-8 bg-white rounded-lg">
        {/* Hero Section */}
        <div className="text-center mb-3">
          <h1 className="lg:text-4xl text-2xl font-bold text-gray-900">
            {t("stay_safe.heading_1")}
          </h1>
          <div className="h-1 w-20 bg-blue-500 mx-auto rounded-full mt-6"></div>
        </div>

        <div className="w-full mx-auto ">
          <img
            src={headingImages.hero}
            alt="Stay Safe Hero"
            className="w-[70%] lg:w-[30%] block mx-auto rounded-lg"
          />
        </div>

        <div className="text-justify mb-12">
          <p className="text-lg text-gray-700">
            {t("stay_safe.sub_heading_1")}
          </p>
        </div>

        {/* General Safety Advice */}
        <Section headingKey="heading_2">
          <SubSectionTitle title={t("stay_safe.sub_heading_2")} />
          <SubSectionContent content={t("stay_safe.sub_heading_2_2_1")} />
          <SubSectionContent content={t("stay_safe.sub_heading_2_2_2")} />
          <SubSectionContent content={t("stay_safe.sub_heading_2_2_3")} />

          <SubSectionTitle title={t("stay_safe.sub_heading_2_3_1")} />
          <SubSectionContent content={t("stay_safe.sub_heading_2_3_2")} />
          <SubSectionContent content={t("stay_safe.sub_heading_2_3_3")} />

          <SubSectionTitle title={t("stay_safe.sub_heading_2_4_1")} />
          <SubSectionContent content={t("stay_safe.sub_heading_2_4_2")} />
          <SubSectionContent content={t("stay_safe.sub_heading_2_4_3")} />
        </Section>

        {/* Common Scams */}
        <Section headingKey="heading_3">
          <SubSectionTitle title={t("stay_safe.sub_heading_3_1")} />
          <SubSectionContent content={t("stay_safe.sub_heading_3_1_1")} />
          <SubSectionContent content={t("stay_safe.sub_heading_3_1_2")} />

          <SubSectionTitle title={t("stay_safe.sub_heading_3_2")} />
          <SubSectionContent content={t("stay_safe.sub_heading_3_2_1")} />

          <SubSectionTitle title={t("stay_safe.sub_heading_3_3")} />
          <SubSectionContent content={t("stay_safe.sub_heading_3_3_1")} />
          <SubSectionContent content={t("stay_safe.sub_heading_3_3_2")} />
        </Section>

        {/* Safety Features */}
        <Section headingKey="heading_4">
          <SubSectionTitle title={t("stay_safe.sub_heading_4_1")} />
          <SubSectionContent content={t("stay_safe.sub_heading_4_1_1")} />

          <SubSectionTitle title={t("stay_safe.sub_heading_4_2")} />
          <SubSectionContent content={t("stay_safe.sub_heading_4_2_1")} />

          <SubSectionTitle title={t("stay_safe.sub_heading_4_3")} />
          <SubSectionContent content={t("stay_safe.sub_heading_4_3_1")} />
          <SubSectionContent content={t("stay_safe.sub_heading_4_3_2")} />
        </Section>

        {/* Reporting Safety Concerns */}
        <Section headingKey="heading_5">
          <SubSectionTitle title={t("stay_safe.sub_heading_5_1")} />
          <SubSectionContent content={t("stay_safe.sub_heading_5_1_1")} />

          <SubSectionTitle title={t("stay_safe.sub_heading_5_2")} />
          <SubSectionContent content={t("stay_safe.sub_heading_5_2_1")} />
        </Section>

        {/* Footer */}
        <div className="mt-12 p-6 bg-yellow-100 rounded-lg">
          <p className="text-gray-700">{t("stay_safe.stay_safe_footer")}</p>
        </div>
      </div>
    </div>
  );
};

export default StaySafe;
