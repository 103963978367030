import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import PostLoading from "../../components/LocalUi/PostLoading";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import { useUser } from "../../context/UserContext";
import CommentSection from "./CommentSection";
import icn_user from "../../img/icn/icn_user.png";

const BlogPostDetails = () => {
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [vlogData, setBlogData] = useState(null);
  const [vlogAuthorData, setBlogAuthorData] = useState(null);
  const [vlogImages, setBlogImages] = useState([]);

  //get current user data
  const { user, error, isLoading } = useUser();
  const userToken = localStorage.getItem("userToken");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/blog/getBlogPostdetails/${id}`
        );
        setBlogData(response.data.BlogPosts);
        setBlogImages(response.data.BlogPosts.imageUrls);
        setBlogAuthorData(response.data.author);
        setPageLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setPageLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const sanitizeHTML = (html) => ({
    __html: DOMPurify.sanitize(html.replace(/\n/g, "<br>")),
  });

  if (pageLoading) return <PostLoading />;
  if (!vlogData)
    return (
      <div className="text-center text-2xl text-gray-600 py-20">
        No post available
      </div>
    );

  return (
    <div
      className={`min-h-screen  w-[98%] md:w-[90%] xl:w-[85%] mx-auto py-8 `}
    >
      <div className="mx-auto p-2  md:p-4 lg:p-6 bg-white rounded-lg ">
        <article className="overflow-hidden">
          <img
            src={vlogImages[0]}
            alt="Blog cover"
            className="w-full h-64 sm:h-80 object-cover rounded-lg"
          />
          <div className="p-2 sm:p-4">
            <p className="text-sm text-gray-500 uppercase tracking-wider">
              {vlogData.Topic}
            </p>
            <h1 className="mt-2 text-3xl sm:text-4xl font-bold text-gray-900 leading-tight">
              {vlogData.Title}
            </h1>
            <div className="mt-4 flex items-center">
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={vlogAuthorData.proPic || icn_user}
                  alt={vlogAuthorData.username}
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">
                  {vlogAuthorData.username}
                </p>
                <p className="text-sm text-gray-500">
                  Posted on {new Date(vlogData.postDate).toLocaleDateString()}
                </p>
              </div>
            </div>
            <div
              className="mt-6 prose text-justify prose-lg max-w-none"
              dangerouslySetInnerHTML={sanitizeHTML(vlogData.Post)}
            />
          </div>

          {/* Add CommentSection component */}
          <CommentSection postId={id} />
        </article>
      </div>
    </div>
  );
};

export default BlogPostDetails;
