import React from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  // Section component for consistent styling
  const Section = ({ title, content, listItems = [], additionalContent }) => (
    <div className="mb-8">
      <div className="flex items-center gap-4 mb-4">
        <div className="w-1.5 h-8 bg-blue-500 rounded-full"></div>
        <h2 className="text-2xl font-bold text-gray-800">
          <Trans i18nKey={title} />
        </h2>
      </div>
      {content && (
        <p className="text-gray-600 leading-relaxed mb-4 ml-6">
          <Trans i18nKey={content} />
        </p>
      )}
      {listItems.length > 0 && (
        <ul className="space-y-3 ml-6">
          {listItems.map((item, index) => (
            <li key={index} className="flex items-start gap-2 text-gray-600">
              <span className="mt-2 block w-1.5 h-1.5 bg-black rounded-full"></span>
              <span className="flex-1">
                <Trans i18nKey={item} />
              </span>
            </li>
          ))}
        </ul>
      )}
      {additionalContent && (
        <p className="text-gray-600 mt-4 ml-6">
          <Trans i18nKey={additionalContent} />
        </p>
      )}
    </div>
  );

  return (
    <div className="min-h-screen w-[98%] md:w-[90%] xl:w-[75%] py-8 mx-auto ">
      <Helmet>
        <title>{t("privacy_policy.title")} - Loko Property</title>
      </Helmet>

      <div className=" mx-auto px-4 py-12 md:px-6 lg:px-8 bg-white rounded-lg">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="lg:text-4xl text-2xl font-bold text-gray-900 mb-6">
            <Trans i18nKey="privacy_policy.title" />
          </h1>
          <div className="h-1 w-20 bg-blue-500 mx-auto rounded-full"></div>
        </div>

        {/* Main Content */}
        <div className=" rounded-xl p-6 md:p-8 lg:p-10 space-y-10">
          {/* Information We Collect */}
          <Section
            title="privacy_policy.Information_We_Collect"
            content="privacy_policy.We_collect_the_following_types"
            listItems={[
              "privacy_policy.Personal_Information_Such_as_your_name",
              "privacy_policy.Property_Related_Any_details_about_properties",
              "privacy_policy.Browsing_Data_Information_such_as_browser_type",
              "privacy_policy.Other_information_users'_IP_address_and standard_web_log_information"
            ]}
          />

          {/* How We Use Your Information */}
          <Section
            title="privacy_policy.How_We_Use_Your_Information"
            content="privacy_policy.We_use_the_information_collected"
            listItems={[
              "privacy_policy.To_create_and_manage_user_accounts",
              "privacy_policy.To_provide_property-related_services_and_customer_support",
              "privacy_policy.To_encourage_safe_buying_selling_and_renting_and_to_enforce_our_policies",
              "privacy_policy.To_personalize_your_experience_and_improve_our_website_functionality",
              "privacy_policy.For_analytics_purposes_to_understand_user_behavior_and_improve_our_services"
            ]}
          />

          {/* Sharing of Information */}
          <Section
            title="privacy_policy.Sharing_of_Information"
            content="privacy_policy.We_may_share_your_information_in_the_following_cases"
            listItems={[
              "privacy_policy.With_Service_Providers_Trusted_third",
              "privacy_policy.For_Legal_Reasons_If_required"
            ]}
             additionalContent="privacy_policy.N_B_We_do_not_sell_your_personal_information_to_third_parties"

          />

          {/* Cookies and Tracking */}
          <Section
            title="privacy_policy.Cookies_and_Tracking_Technologies"
            content="privacy_policy.We_use_cookies_and_similar_technologies"
            listItems={[
              "privacy_policy.Enhance_site_functionality_and_personalize",
              "privacy_policy.Analyze_website_traffic_and_user_behavior_for_improvements"
            ]}
            additionalContent="privacy_policy.You_can_manage_or_disable_cookies_through_your_browser_settings_at_any_time"
          />

          {/* Data Security */}
          <Section
            title="privacy_policy.Data_Security"
            content="privacy_policy.We_take_the_security_of_your_data_seriously"
            listItems={[
              "privacy_policy.Using_secure_servers_and_encryption",
              "privacy_policy.Regular_monitoring_for_potential_security_breaches"
            ]}
            additionalContent="privacy_policy.However_no_method_of_transmission_over_the_internet"
          />

          {/* User Rights */}
          <Section
            title="privacy_policy.User_Rights_and_Choices"
            listItems={[
              "privacy_policy.Access_You_can_request_to_see_the_personal_data_we_hold_about_you",
              "privacy_policy.Modification_You_can_request",
              "privacy_policy.Deletion_You_can_request"
            ]}
          />

          {/* Third Party Links */}
          <Section
            title="privacy_policy.Third_Party_Links"
            content="privacy_policy.Our_website_may_contain_links_to_third-party_websites"
          />

          {/* Changes to Policy */}
          <Section
            title="privacy_policy.Changes_to_the_Privacy_Policy"
            content="privacy_policy.We_may_update_this_privacy_policy_from_time_to_time"
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;