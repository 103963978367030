import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CgSpinner } from "react-icons/cg";

import { toast, Toaster } from "react-hot-toast";

import { Mail, Key, Eye, EyeOff, RotateCw } from "lucide-react";

import Logo from "../../img/icn/Logo.png";
import signBg from "../../img/signbg.jpg";

const Register = () => {
  // check current language
  const lang = localStorage.getItem("lng");

  const { refreshUserData } = useUser();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  ///Language
  const { t, i18n } = useTranslation();

  const [step, setStep] = useState(1);

  // Form states
  const [formData, setFormData] = useState({
    email: "",
    verificationCode: "",
    verificationId: "",
    username: "",
    number: "",
    birthDate: null,
    gender: "",
    password: "",
    confirmPassword: "",
  });

  // UI states
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Check for existing session
  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      navigate("/profile");
    }
  }, [navigate]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle phone number input
  const handlePhoneChange = (e) => {
    const value = e.target.value;

    // If it's the first digit and it's not 0, prevent the input
    if (value.length === 1 && value !== "0") {
      e.target.value = ""; // Clear the input
      e.target.setCustomValidity("Phone number must start with 0");
      e.target.reportValidity();
      return;
    }

    // If it's not the first digit and the first digit is not 0, prevent the input
    if (value.length > 1 && !value.startsWith("0")) {
      e.target.value = value.slice(0, -1); // Remove the last entered digit
      e.target.setCustomValidity("Phone number must start with 0");
      e.target.reportValidity();
      return;
    }

    e.target.setCustomValidity(""); // Clear any previous validation message

    setFormData((prev) => ({
      ...prev,
      number: value,
    }));
  };

  // Handle date change
  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setFormData((prev) => ({
      ...prev,
      birthDate: formattedDate,
    }));
  };

  // Validate email format
  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Step 1: Send verification email
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      toast.error(t("register.please_provide_email_address"));
      return;
    }

    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/send-verification`,
        { email: formData.email }
      );
      setFormData((prev) => ({
        ...prev,
        verificationId: data.verificationId,
      }));
      setStep(2);
      toast.success(t("register.verification_code_sent_to_your_email"));

      // Start the countdown after successful email send
      setVerificationCountdown(300);
    } catch (error) {
      const errorMessage = error.response?.data?.error;

      if (
        error.response?.data?.success === false &&
        errorMessage === "Email already registered"
      ) {
        toast.error(t("register.email_already_registered"));
      } else {
        toast.error(errorMessage || "Failed to send verification code");
      }
    } finally {
      setLoading(false);
    }
  };

  const [verificationCountdown, setVerificationCountdown] = useState(30); // 10 minutes
  const [resendCooldown, setResendCooldown] = useState(0);

  // Format countdown to minutes:seconds
  const formatCountdown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // Countdown timer effect
  useEffect(() => {
    let timer;
    if (verificationCountdown > 0) {
      timer = setInterval(() => {
        setVerificationCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [verificationCountdown]);

  // Resend cooldown timer effect
  useEffect(() => {
    let cooldownTimer;
    if (resendCooldown > 0) {
      cooldownTimer = setInterval(() => {
        setResendCooldown((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(cooldownTimer);
  }, [resendCooldown]);

  // Resend Verification Email
  const ResendVerificationEmail = async () => {
    // Prevent sending if cooldown is active
    if (resendCooldown > 0) return;

    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/resend-verification`,
        {
          email: formData.email,
          verificationId: formData.verificationId,
        }
      );

      // Update verification ID if a new one is generated
      if (data.verificationId) {
        setFormData((prev) => ({
          ...prev,
          verificationId: data.verificationId,
        }));
      }

      // Reset countdown
      setVerificationCountdown(300); // Reset to 5 minutes
      setResendCooldown(300); // 60 seconds cooldown between resends

      toast.success("New verification code sent to your email");
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      toast.error(errorMessage || "Failed to resend verification code");
    } finally {
      setLoading(false);
    }
  };

  // Step 2: Verify email code
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (!formData.verificationCode) {
      toast.error("Please enter the verification code");
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/verify-email`,
        {
          verificationId: formData.verificationId,
          verificationCode: formData.verificationCode,
        }
      );
      setStep(3);
      toast.success("Email verified successfully");
    } catch (error) {
      toast.error(error.response?.data?.error || "Invalid verification code");
    } finally {
      setLoading(false);
    }
  };

  // Step 3: Complete registration
  const handleCompleteRegistration = async (e) => {
    e.preventDefault();

    // Validation checks

    if (!formData.gender) {
      toast.error( t("register.please_select_your_Gender"));
      return;
    }

    if (!formData.birthDate) {
      toast.error( t("register.please_select_your_Birth_Date"));
      return;
    }

    if (
      !formData.username ||
      !formData.number ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      toast.error( t("register.please_fill_out_all_fields"));
      return;
    }

    if (!/^[a-zA-Z\s]{4,}$/.test(formData.username)) {
      toast.error(
        t("register.name_must_be_at_least_4_characters_and_contain_only_letters")
      );
      return;
    }

    if (formData.password.length < 6) {
      toast.error( t("register.password_must_be_at_least_6_characters_long"));
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      toast.error( t("register.passwords_do_not_match"));
      return;
    }

    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        {
          username: formData.username,
          number: formData.number,
          email: formData.email,
          birthDate: formData.birthDate,
          gender: formData.gender,
          password: formData.password,
          verificationId: formData.verificationId,
        }
      );

      localStorage.setItem("userToken", data.token);
      toast.success(t("register.registration_successful"));
      await refreshUserData();
      navigate("/profile");
    } catch (error) {
      toast.error(error.response?.data?.error || "Registration failed");
    } finally {
      setLoading(false);
    }
  };

  // Render different steps
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <form onSubmit={handleEmailSubmit} className="space-y-4">
            <input
              type="email"
              name="email"
              className="h-12 w-80 text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
              placeholder={t("register.email_placeholder")}
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <button
              type="submit"
              className="w-full bg-[#F59E0B] hover:bg-[#fd7200] py-2 text-white rounded-xl flex items-center justify-center gap-2"
              disabled={loading}
            >
              {loading && <CgSpinner className="animate-spin" />}
              {loading
                ? t("register.sending")
                : t("register.send_verification_code")}
            </button>
          </form>
        );

      case 2:
        return (
          <form onSubmit={handleVerifyCode} className="space-y-4">
            <div>
              <input
                type="text"
                name="verificationCode"
                className="h-12 w-80 text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
                placeholder={t("register.enter_verification_code")}
                value={formData.verificationCode}
                onChange={handleInputChange}
                required
              />

              <div>
                {/* If you want a resend button that's disabled during countdown */}
                {verificationCountdown > 0 ? (
                  <p
                    disabled
                    className="text-gray-400 text-right cursor-not-allowed"
                  >
                    {t("register.code_expired_in")}:{" "}
                    {formatCountdown(verificationCountdown)}
                  </p>
                ) : (
                  <p
                    onClick={ResendVerificationEmail}
                    className="text-blue-600 text-right cursor-pointer"
                  >
                    {t("register.resend_code")}
                  </p>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-[#F59E0B] hover:bg-[#fd7200] py-2 text-white rounded-xl flex items-center justify-center gap-2"
              disabled={loading}
            >
              {loading && <CgSpinner className="animate-spin" />}
              {loading ? t("register.verifying") : t("register.verify_code")}
            </button>
          </form>
        );

      case 3:
        return (
          <form
            onSubmit={handleCompleteRegistration}
            className=" mt-2 space-y-3 w-full max-w-md mx-auto"
          >
            <input
              type="text"
              name="username"
              className="h-12 w-full text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
              placeholder={t("register.full_name")}
              value={formData.username}
              onChange={handleInputChange}
              required
            />

            <div className="w-full rounded-[15px]">
              <input
                type="tel"
                name="number"
                className="h-12 w-full text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
                onChange={handlePhoneChange}
                placeholder={t("register.phone_number")}
                maxLength="11"
                required
              />
            </div>

            <Box className="w-full">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("register.birth_date")}
                  value={formData.birthDate ? dayjs(formData.birthDate) : null}
                  onChange={handleDateChange}
                  sx={{
                    width: "100%",
                    borderRadius: "15px",
                    background: "white",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "15px",
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>

            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                className="h-12 w-full text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
                placeholder={t("register.password")}
                value={formData.password}
                onChange={handleInputChange}
                required
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-500" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>

            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="confirmPassword"
                className="h-12 w-full text-black border rounded-xl border-cyan-900 placeholder-gray-900 p-3"
                placeholder={t("register.confirm_password")}
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-500" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-500" />
                )}
              </button>
            </div>

            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-3 sm:space-y-0 sm:space-x-2">
              <span className="text-lg">{t("register.gender")}:</span>
              <div className="flex flex-wrap gap-2">
                {[
                  t("register.male"),
                  t("register.female"),
                  t("register.other"),
                ].map((option) => (
                  <label key={option} className="cursor-pointer">
                    <input
                      type="radio"
                      name="gender"
                      value={option}
                      checked={formData.gender === option}
                      onChange={handleInputChange}
                      className="peer sr-only"
                    />
                    <div className="w-24 sm:w-20 text-center p-1 rounded-md bg-white ring-2 ring-transparent transition-all peer-checked:ring-blue-400 peer-checked:bg-[#caffb1]">
                      <p className="text-sm font-semibold text-gray-500">
                        {option}
                      </p>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-[#F59E0B] hover:bg-[#fd7200] py-3 text-white rounded-xl flex items-center justify-center gap-2"
              disabled={loading}
            >
              {loading && <CgSpinner className="animate-spin" />}
              {loading
                ? t("register.registering")
                : t("register.complete_registration")}
            </button>

            <p className="text-center mt-4 text-sm sm:text-base">
              {t("register.already_have_account")}
              <Link to="/SignIn" className="text-blue-500 font-bold italic">
                {t("register.login_now")}
              </Link>
            </p>
          </form>
        );

      default:
        return null;
    }
  };

  return (
    <div className=" min-h-screen w-full flex justify-center items-center duration-200 ">
      <Helmet>
        <title> {t("register.register")} - Loko Property</title>
      </Helmet>

      <div className=" mt-5 lg:h-[600px] h-[105vh] xl:w-[70%]  lg:w-[80%]  md:w-[80%]  w-[95%] bg-gradient-to-b lg:bg-gradient-to-r from-black via-red-300 to-orange-200 lg:flex justify-center shadow-xl items-center rounded-[20px] duration-300">
        <div className="lg:h-full lg:w-[50%] lg:flex h-[150px] relative justify-center items-center">
          <img
            className=" opacity-30 h-full w-full lg:object-fill absolute lg:rounded-l-[20px] rounded-t-[20px]"
            src={signBg}
            alt="bg"
          />

          <div className="absolute lg:w-[400px] w-full lg:mt-0 mt-3">
            <img
              class=" m-auto block lg:h-[150px] h-[60px] lg:w-[150px]  w-[60px]  duration-200 mb-5"
              src={Logo}
              alt="logo"
            />

            <p
              className={`px-6 lg:text-2xl text-lg text-white text-center duration-200`}
            >
              {t("home.heading_1")}
            </p>
          </div>

          <div className="leftItem "></div>
        </div>

        <div className="lg:h-full lg:w-[50%] h-[350px]  p-10  ">
          <div className=" h-full w-full">
            <div className="   ">
              <p className=" lg:text-2xl  text-xl text-center lg:text-gray-700 text-white font-semibold">
                {t("register.register_heading")}
              </p>
            </div>

            <div className=" h-full w-full flex items-center justify-center">
              <div className=" mt-5">
                <p className="lg:text-xl mb-3 text-lg lg:text-gray-700 text-white font-semibold">
                  {step === 1
                    ? t("register.email")
                    : step === 2
                    ? t("register.verify_your_email")
                    : t("register.complete_registration")}
                </p>
                {renderStep()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
