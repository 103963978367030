import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useUser } from "../../../context/UserContext";
import PostLoading from "../../../components/LocalUi/PostLoading";
import { Helmet } from "react-helmet";

// icons
import premium from "../../../img/icn/premium.png";
import copy from "../../../svg/copy.svg";
import locations from "../../../img/icn/location.png";
import not_found from "../../../img/not-found.png";
import call from "../../../img/icn/call.png";
import save from "../../../img/icn/love.png";
import unsave from "../../../img/icn/unsave.png";
import share from "../../../img/icn/share.png";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";

const InteriorPropertyDetails = () => {
  const { id } = useParams();
  const currentUser = localStorage.getItem("userToken");
  const location = useLocation();
  const navigate = useNavigate();

  const [interiorPost, setInteriorPost] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [contactModal, setContactModal] = useState(false);
  const font = localStorage.getItem("lng");
  const setFont = font === "bn" ? "font-TiroBangla" : "font-Poppins-Regular";

  //get current user data
  const { user, error, isLoading } = useUser();
  const userToken = localStorage.getItem("userToken");

  //get user saved post
  const [savedPosts, setSavedPosts] = useState([]);
  const userID = user?.id; // Use optional chaining to prevent errors if user is undefined

  //get user SavePost
  useEffect(() => {
    // Check if user exists before making the Axios request
    if (user) {
      const handleFetchSavedPosts = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/profile/getSavePost`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`, // Ensure userToken is defined
              },
            }
          );
          setSavedPosts(response.data.SavedPosts);
        } catch (error) {
          console.error("Error making Axios request:", error);
        }
      };

      handleFetchSavedPosts();
    }
  }, [user]); // Run when the user changes

  useEffect(() => {
    const fetchInteriorPostDetails = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/InteriorPostDetails/${id}`;
        const response = await axios.get(apiUrl);
        setInteriorPost(response.data.interiorPost);
      } catch (error) {
        setErrorMessage("Interior property not found");
        console.error("Error fetching data:", error);
      }
    };

    fetchInteriorPostDetails();
  }, [id]);

  //Save Un-save Optimistic UI Update
  const handleSaveUnSavePost = async (event, postId) => {
    event.stopPropagation();

    if (!currentUser) {
      localStorage.setItem("redirectUrl", location.pathname);
      toast.error("You have to login first");
      navigate("/SignIn");
    } else {
      const userID = user.id;
      const PostID = postId;
      const isPostSaved = savedPosts.some(
        (savedPost) => savedPost._id === PostID
      );

      try {
        if (isPostSaved) {
          // Optimistically update the UI
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
          toast.success("Post Unsaved");

          // Make the network request in the background
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/profile/deleteUserSavedPost/${PostID}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
        } else {
          // Optimistically update the UI
          const newSavedPost = { _id: PostID }; // Create a minimal representation of the saved post
          setSavedPosts((prevPosts) => [...prevPosts, newSavedPost]);
          toast.success("Post Saved");

          // Make the network request in the background
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/profile/setSavePost`,
            { userID, PostID },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          // Update the UI with the full post details from the server response
          const savedPost = response.data.SavedPosts.find(
            (post) => post._id === PostID
          );
          setSavedPosts((prevPosts) =>
            prevPosts.map((post) => (post._id === PostID ? savedPost : post))
          );
        }
      } catch (error) {
        // Revert the optimistic UI update on error
        if (isPostSaved) {
          setSavedPosts((prevPosts) => [...prevPosts, { _id: PostID }]);
        } else {
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
        }

        console.error("Error saving/unsaving post:", error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.error;
          console.error("Server error message:", errorMessage);
          //toast.error(`Error: ${errorMessage}`);
        } else {
          //toast.error("Error saving/unsaving post");
        }
      }
    }
  };

  if (!interiorPost && !errorMessage) {
    return (
      <div className="w-full h-[90vh] flex justify-center">
        <div className="w-full md:w-[70%] px-4">
          <PostLoading />
        </div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="w-full h-[90vh] flex justify-center items-center px-4">
        <div className="text-center">
          <img
            className="mx-auto w-1/2 md:w-1/3 max-w-xs"
            src={not_found}
            alt="not found"
          />
          <p className="text-3xl md:text-5xl mt-5 font-bold text-red-200">
            {errorMessage}
          </p>
          <div className="mt-10">
            <Link
              to="/Interior-Design"
              className="inline-block border-2 hover:bg-slate-200 duration-200 py-2 px-5 rounded-lg text-xl font-bold text-gray-500"
            >
              ← Go Back
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const { images } = interiorPost;

  //popup modal`

  const openModal = () => {
    setContactModal(true);
  };

  const closeModal = () => {
    setContactModal(false);
  };

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(interiorPost.number);
    toast.success("Number copied to clipboard!");
    setContactModal(false);
  };
  const copyToClipboard2 = () => {
    navigator.clipboard.writeText(interiorPost.moreNumber);
    toast.success("Number copied to clipboard!");
    setContactModal(false);
  };

  return (
    <div className={`MainSection ${setFont} w-full flex justify-center`}>
      <Helmet>
        <title>Interior Details - Loko Property</title>
      </Helmet>
      <div className="w-full md:w-[90%] lg:w-[80%] xl:w-[70%] px-4">
        <div>
          <div className="mt-10 h-[30vh] md:h-[40vh] lg:h-[50vh] relative">
            <img
              src={interiorPost.coverPhoto}
              className="h-full w-full object-cover absolute"
              alt="background"
            />
            <div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
            <div className="absolute bottom-0 p-4 md:p-6 lg:p-10 w-full">
              <div className="relative">
                <p className="text-2xl md:text-3xl lg:text-4xl font-bold text-white">
                  {interiorPost.companyName}
                </p>
                <p className="text-lg md:text-xl text-white mt-2">
                  {interiorPost.companySlogan}
                </p>
              </div>
            </div>
          </div>

          <div className="my-4">
            <div className=" mt-3 flex justify-between items-center ">
              <div className="flex items-center">
                <img
                  src={locations}
                  className="h-5 w-5 mr-2"
                  alt="location icon"
                />
                <p className="text-sm md:text-base">
                  Address: {interiorPost.subDistrict}, {interiorPost.district},{" "}
                  {interiorPost.division}
                </p>
              </div>

              <div className="md:flex md:space-y-0 space-y-1 justify-center items-center md:space-x-1 duration-300 ">
                {interiorPost.isPremium && (
                  <div className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-10 w-8  ">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="flex items-center">
                            <img
                              src={premium}
                              className="h-8 w-8 mr-2"
                              alt="Premium star"
                            />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p className=" text-red-600 ">Premium</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                )}

                <div
                  onClick={openModal}
                  className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[110px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-red-200 hover:shadow-lg duration-200"
                >
                  <img
                    src={call}
                    className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                    alt="name"
                  />

                  <div className="lg:text-[16px] hidden lg:block ">
                    {"Contact"}
                  </div>
                </div>

                <button
                  onClick={(event) =>
                    handleSaveUnSavePost(event, interiorPost._id)
                  }
                >
                  <div className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[100px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-green-200 hover:shadow-lg duration-200">
                    {savedPosts.some(
                      (savedPost) => savedPost._id === interiorPost._id
                    ) ? (
                      <div className="flex lg:space-x-2">
                        <img
                          src={save}
                          className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                          alt="Filled Love Icon"
                        />
                        <div className="lg:text-[16px] hidden lg:block">
                          {"Saved"}
                        </div>
                      </div>
                    ) : (
                      <div className="flex lg:space-x-2">
                        <img
                          src={unsave}
                          className="lg:h-6 h-5 lg:w-6 w-5 duration-200"
                          alt="Empty Love Icon"
                        />
                        <div className="lg:text-[16px] hidden lg:block">
                          {"Save"}
                        </div>
                      </div>
                    )}
                  </div>
                </button>

                <div className="m-auto flex items-center justify-evenly lg:h-10 h-8 lg:w-[100px] w-8 px-1 border border-gray-400 cursor-pointer rounded-lg hover:scale-105 hover:bg-green-200 hover:shadow-lg duration-200">
                  <img
                    src={share}
                    className="lg:h-6 h-5 lg:w-6 w-6 duration-200"
                    alt="name"
                  />
                  <div className="lg:text-[16px] hidden lg:block ">
                    {"Share"}
                  </div>
                </div>
              </div>
            </div>

            <p className="mt-6 text-xl md:text-2xl text-gray-700 font-bold">
              Description
            </p>
            <p className="mt-3 text-sm md:text-base text-gray-900">
              {interiorPost.postDetails}
            </p>
            {images && Object.keys(images).length > 0 && (
              <div className="w-full mt-8 md:mt-16">
                <div className="space-y-8 md:space-y-16">
                  {Object.keys(images).map((key) => (
                    <div
                      key={key}
                      className="border border-gray-300 rounded-b-lg"
                    >
                      <img
                        src={images[key]}
                        alt={`${key} design`}
                        className="w-full"
                      />
                      <h3 className="text-center py-2 text-sm md:text-base text-gray-500 font-semibold">
                        {key.charAt(0).toUpperCase() + key.slice(1)} Design
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {contactModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg mx-3 p-5 ">
            <h2 className="text-xl font-semibold mb-4">Contact Number</h2>
            <div className="flex items-center space-x-5 ">
              <img
                src={call}
                className="h-8 w-8 lg:w-10 lg:h-10  duration-200"
                alt="name"
              />

              {isMobile() ? (
                <div className="flex flex-col">
                  <a
                    href={`tel:${interiorPost.number}`}
                    className="text-blue-500"
                  >
                    {interiorPost.number}
                  </a>

                  <a
                    href={`tel:${interiorPost.moreNumber}`}
                    className="text-blue-500"
                  >
                    {interiorPost.moreNumber}
                  </a>
                </div>
              ) : (
                <div className=" ">
                  <p> আমাদের সাথে যোগাযোগ করুন </p>

                  <div className=" mt-5">
                    {"1."}

                    <div className=" flex">
                      {" "}
                      <span className=" text-blue-600 font-semibold">
                        {interiorPost.number}
                      </span>
                      <div
                        className="px-2 cursor-pointer"
                        onClick={copyToClipboard}
                      >
                        <img src={copy} alt="copy" />
                      </div>{" "}
                    </div>
                  </div>

                  {interiorPost.moreNumber && (
                    <div className=" mt-5">
                      {"2."}
                      <div className=" flex">
                        <span className=" text-blue-600 font-semibold">
                          {interiorPost.moreNumber}
                        </span>
                        <div
                          className="px-2 cursor-pointer"
                          onClick={copyToClipboard2}
                        >
                          <img src={copy} alt="copy" />
                        </div>{" "}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mt-6 flex justify-end">
              <button
                className="px-4 py-1 bg-red-300 rounded-md"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InteriorPropertyDetails;
