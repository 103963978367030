import React, { useState, useRef, useEffect } from "react";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

import { FaCamera } from "react-icons/fa";

//icon
import { CgSpinner } from "react-icons/cg";
import icn_user from "../../img/icn/icn_user.png";
import icn_number from "../../img/icn/icn_phone.png";
import icn_addPhoto from "../../img/icn/addPhoto.png";
import icn_email from "../../img/icn/icn_email.png";
import icn_date from "../../img/icn/icn_date.png";
import icn_gender from "../../img/icn/icn_gender.png";

const EditProfile = () => {
  const { refreshUserData } = useUser();
  const userToken = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const { user, error, isLoading } = useUser();
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  // Form states
  const [displayPic, setDisplayPic] = useState(null);
  const [userPic, setUserPic] = useState(null);
  const [userName, setUserName] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [userAbout, setUserAbout] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userOccupation, setUserOccupation] = useState("");

  useEffect(() => {
    if (user) {
      setUserPic(user.proPic);
      setUserName(user.name);
      setUserNumber(user.number || ""); // Ensure it's not null
      setUserAbout(user.about || "");
      setUserAddress(user.address || "");
      setUserOccupation(user.occupation || "");
    }
  }, [user]);

  //getting image from Form
  const handleImageIconClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    // Ensure only one file is selected
    if (e.target.files.length === 1) {
      setDisplayPic(URL.createObjectURL(e.target.files[0]));
      const selectedImage = e.target.files[0];
      setUserPic(selectedImage);
    }
  };

  const validatePhoneNumber = (number) => {
    // Add your phone number validation logic here
    return number && number.length >= 10;
  };

  // Number input handler with validation
  const handleNumberChange = (e) => {
    const value = e.target.value;
    // Only allow numbers
    if (/^\d*$/.test(value)) {
      setUserNumber(value);
    }
  };

  const HandleUpdateProfile = async (e) => {
    e.preventDefault();

    // Validate phone number
    if (!validatePhoneNumber(userNumber)) {
      toast.error("Please enter a valid phone number");
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("proPic", userPic);
      formData.append("username", userName);
      formData.append("number", userNumber);
      formData.append("about", userAbout);
      formData.append("address", userAddress);
      formData.append("occupation", userOccupation);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/profile/updateprofile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data) {
        toast.success("Profile updated successfully");
        await refreshUserData();
      } else {
        toast.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error(error.response?.data?.message || "Failed to update profile");
    } finally {
      setLoading(false);
    }
  };



  return error ? (
    <span className="error-message">{error}</span>
  ) : isLoading ? (
    <div className="text-[40px] flex  h-screen w-full justify-evenly items-center duration-200 border-2 border-red text-gray-600  ">
      Loading...
    </div>
  ) : (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8"
    >
      <div className="container mx-auto px-4 py-8">
        <Helmet>
          <title>Profile Edit - Loko Property</title>
        </Helmet>
        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <form
            onSubmit={HandleUpdateProfile}
            encType="multipart/form-data"
            className="md:flex"
          >
            {/* Left side - Profile picture and basic info */}
            <div className="md:w-1/3 p-8 bg-gray-50">
              <div className="relative w-48 h-48 mx-auto mb-6">
                <img
                  src={displayPic || user.proPic || icn_user}
                  alt="Profile"
                  className="w-full h-full object-cover rounded-full border-4 border-white shadow-lg"
                />

                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full shadow-lg"
                  onClick={(e) => {
                    e.preventDefault();
                    fileInputRef.current.click();
                  }}
                >
                  <FaCamera />
                </motion.button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".jpg, .jpeg, .png"
                  onChange={handleImageUpload}
                />
              </div>

              <div className="space-y-4">
                <div className="flex items-center border-b border-gray-300 py-2">
                  <img src={icn_user} alt="User" className="w-6 h-6 mr-3" />
                  <input
                    className="w-full p-2 focus:outline-none"
                    value={userName}
                    type="text"
                    name="text"
                    required
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Full Name"
                  />
                </div>

                <div className="flex items-center border-b border-gray-300 py-2">
                  <img src={icn_number} alt="Number" className="w-6 h-6 mr-3" />
                  <input
                    className="w-full p-2 bg-gray-100"
                    value={userNumber}
                    type="tel"
                    name="number"
                    required
                    onChange={handleNumberChange}
                    placeholder="Enter phone number"
                    maxLength={15}
                  />
                </div>

                <div className="flex items-center border-b border-gray-300 py-2">
                  <img src={icn_email} alt="Email" className="w-6 h-6 mr-3" />
                  <input
                    className="w-full p-2 focus:outline-none"
                    value={user.email}
                    type="text"
                    name="text"
                    readOnly
                    placeholder="Email"
                  />
                </div>
                <div className="flex items-center border-b border-gray-300 py-2">
                  <img src={icn_date} alt="Date" className="w-6 h-6 mr-3" />
                  <input
                    className="w-full p-2 bg-gray-100"
                    value={user.birthDate}
                    type="text"
                    readOnly
                    placeholder="Birth Date"
                  />
                </div>
                <div className="flex items-center border-b border-gray-300 py-2">
                  <img src={icn_gender} alt="Gender" className="w-6 h-6 mr-3" />
                  <input
                    className="w-full p-2 bg-gray-100"
                    value={user.gender}
                    type="text"
                    readOnly
                    placeholder="Gender"
                  />
                </div>
              </div>
            </div>

            {/* Right side - Additional information */}
            <div className="md:w-2/3 p-8">
              <h2 className="text-2xl font-semibold mb-6">
                Additional Information
              </h2>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    About:
                  </label>
                  <textarea
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                    rows="4"
                    value={userAbout}
                    onChange={(e) => setUserAbout(e.target.value)}
                  ></textarea>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Address:
                  </label>
                  <textarea
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                    rows="3"
                    value={userAddress}
                    onChange={(e) => setUserAddress(e.target.value)}
                  ></textarea>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Occupation
                  </label>
                  <textarea
                    className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                    rows="2"
                    value={userOccupation}
                    onChange={(e) => setUserOccupation(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
          </form>

          {/* Update button */}
          <div className="px-8 py-4 bg-gray-50 border-t border-gray-200 flex justify-end">
            <button
              type="submit"
              onClick={HandleUpdateProfile}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg flex items-center"
              disabled={loading}
            >
              {loading && <CgSpinner size={20} className="mr-2 animate-spin" />}
              <span>Update Profile</span>
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default EditProfile;
