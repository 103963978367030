import "./App.css";

import React, { useEffect } from "react";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Routing
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import UserIsNotSuspendRoute from "./components/privateRoute/UserIsNotSuspendRoute";

import Navbar from "./components/LocalUi/Navbar";
import Footer from "./components/LocalUi/Footer";

//basic
import Home from "./pages/Home";
import BlogPosts from "./pages/BlogPosts/BlogPosts";
import BlogPostDetails from "./pages/BlogPosts/BlogPostDetails";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import CustomerCare from "./pages/CustomerCare";
import DownloadApp from "./pages/DownloadApp";

//property pages
import PropertySell from "./pages/PropertyPages/PropertySell/PropertySell";
import PropertySellDetails from "./pages/PropertyPages/PropertySell/PropertySellDetails";
import GroupWiseBuilding from "./pages/PropertyPages/GroupWiseBuilding/GroupWiseBuilding";
import GroupWiseBuildingDetails from "./pages/PropertyPages/GroupWiseBuilding/GroupWiseBuildingDetails";

import PropertyRent from "./pages/PropertyPages/PropertyRent/PropertyRent";
import PropertyRentDetails from "./pages/PropertyPages/PropertyRent/PropertyRentDetails";

//inner pages
import SellPost from "./pages/PropertyPages/PropertySell/SellPost";
import RentPost from "./pages/PropertyPages/PropertyRent/RentPost";

//user
import SignIn from "./pages/user/SignIn";
import Register from "./pages/user/Register";
import ForgotPassword from "./pages/user/ForgotPassword";
import ResetPassword from "./pages/user/ResetPassword";
import PageNotFound from "./pages/PageNotFound";
import EditProfile from "./pages/user/EditProfile";
import EditRentPost from "./pages/user/EditRentPost";
import EditSellPost from "./pages/user/EditSellPost";
import UserDashboard from "./pages/user/UserDashboard";

//admin
import Admin from "./pages/admin/Login";
import Dashboard from "./pages/admin/DashBoard";
import UserManagement from "./pages/admin/UserManagement";
import AdminInfo from "./pages/admin/AdminInfo";
import GroupBuildingManage from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/GroupBuildingManage";
import SetGroupBuildingPost from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/SetGroupBuildingPost";

import Notification from "./pages/admin/Notifications";
import SellPostManagement from "./pages/admin/PropertyManagement/PropertySellManagement/SellPostManagement";
import RentPostManagement from "./pages/admin//PropertyManagement/PropertyRentManagement/RentPostManagement";
import NoticeBoard from "./pages/admin/NoticeBoard";

import { UserProvider } from "./context/UserContext";
import ScrollToTop from "./components/LocalUi/ScrollToTop";

import UserProfileDetails from "./pages/UsersData/UserProfileDetails";
import UserPosts from "./pages/UsersData/UsersPosts";

import GroupPostDetailsForAd from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/GroupPostDetailsForAd";
import UpdateGroupBuildingPost from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/UpdateGroupBuildingPost";
import BlogPost from "./pages/admin/BlogPost";
import InteriorManagement from "./pages/admin/PropertyManagement/InteriorManagement/InteriorManagement";
import InteriorAddPost from "./pages/admin/PropertyManagement/InteriorManagement/InteriorAddPost";

import UpdateInteriorPost from "./pages/admin/PropertyManagement/InteriorManagement/InteriorPostEdit";

import InteriorProperty from "./pages/PropertyPages/Interior/InteriorProperty";
import InteriorPropertyDetails from "./pages/PropertyPages/Interior/InteriorPropertyDetails";
import SiteSettings from "./pages/admin/SiteSettings";
import GroupBuildingDesiredRequest from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/GroupBuildingDesiredRequest";
import AdRoute from "./components/privateRoute/AdRoute";
import FastSellRent from "./pages/OthersPage/FastSellRent";
import PrivacyPolicy from "./pages/OthersPage/PrivacyPolicy";
import StaySafe from "./pages/OthersPage/StaySafe";

function App() {
  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  // Function to send visitor data to the backend
  const sendVisitorData = async () => {
    const isMobile = isMobileDevice();
    const data = {
      month: new Date().toLocaleString("default", { month: "long" }), // Get current month (e.g., "July")
      deviceType: isMobile ? "mobile" : "desktop",
    };

    try {
      // Use axios to send a POST request
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/dvd`,
        data
      );
      console.log("Visitor data updated:", response.data);
    } catch (error) {
      console.error("Error sending visitor data:", error);
    }
  };

  useEffect(() => {
    // Call the visitor data function when the app is loaded
    sendVisitorData();
  }, []); // Empty dependency array ensures this runs once on mount

  const { t, i18n } = useTranslation();
  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TiroBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  return (
    <BrowserRouter>
      <div className={` bg-[#ededed] flex flex-col ${setFont}  min-h-screen `}>
        <UserProvider>
          <div className="flex-1  ">
            <Navbar />
            <Toaster position="top-center" toastOptions={{ duration: 5000 }} />
            <ScrollToTop />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Property-Buy-Sell" element={<PropertySell />} />
              <Route path="/Property-Rent" element={<PropertyRent />} />

              <Route path="/CustomerCare" element={<CustomerCare />} />
              <Route path="/FastSellRent" element={<FastSellRent />} />
              <Route path="/Blog" element={<BlogPosts />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/ContactUs" element={<ContactUs />} />

              <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
              <Route path="/Stay-Safe" element={<StaySafe />} />


              <Route path="/DownloadApp" element={<DownloadApp />} />

              <Route
                path="/Group-Wise-Building"
                element={<GroupWiseBuilding />}
              />

              <Route path="/Interior-Design" element={<InteriorProperty />} />
              <Route
                path="/Interior-Details/:id"
                element={<InteriorPropertyDetails />}
              />

              <Route
                path="/Profile"
                element={
                  <PrivateRoute>
                    <UserDashboard />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Edit-Rent-Post/:id"
                element={
                  <PrivateRoute>
                    <EditRentPost />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Edit-Sell-Post/:id"
                element={
                  <PrivateRoute>
                    <EditSellPost />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profileedit"
                element={
                  <PrivateRoute>
                    <EditProfile />
                  </PrivateRoute>
                }
              />

              <Route
                path="/sellpost"
                element={
                  <PrivateRoute>
                    <UserIsNotSuspendRoute>
                      <SellPost />
                    </UserIsNotSuspendRoute>
                  </PrivateRoute>
                }
              />

              <Route
                path="/rentpost"
                element={
                  <PrivateRoute>
                    <UserIsNotSuspendRoute>
                      <RentPost />
                    </UserIsNotSuspendRoute>
                  </PrivateRoute>
                }
              />

              <Route path="/SignIn" element={<SignIn />} />

              <Route
                path="/Property-Buy-Sell-Details/:id"
                element={<PropertySellDetails />}
              />

              <Route
                path="/ProfileDetails/:id"
                element={<UserProfileDetails />}
              />
              <Route path="/UserPosts/:id" element={<UserPosts />} />

              <Route
                path="/Property-Rent-Details/:id"
                element={<PropertyRentDetails />}
              />

              <Route
                path="/Group-Wise-Building-Details/:id"
                element={<GroupWiseBuildingDetails />}
              />

              <Route
                path="/BlogPostDetails/:id"
                element={<BlogPostDetails />}
              />

              <Route path="/Register" element={<Register />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route
                path="/ResetPassword/:resetToken"
                element={<ResetPassword />}
              />
              <Route path="*" element={<PageNotFound />} />

              <Route path="/admin" element={<Admin />} />

              <Route
                path="/admin/Group-Wise-Building-Details-Ad/:id"
                element={
                  <AdRoute>
                    <GroupPostDetailsForAd />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/dashboard"
                element={
                  <AdRoute>
                    <Dashboard />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/admininfo"
                element={
                  <AdRoute>
                    <AdminInfo />
                  </AdRoute>
                }
              />
              <Route
                path="/admin/BlogPost"
                element={
                  <AdRoute>
                    <BlogPost />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/Notifications"
                element={
                  <AdRoute>
                    <Notification />
                  </AdRoute>
                }
              />
              <Route
                path="/admin/interior"
                element={
                  <AdRoute>
                    <InteriorManagement />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/interiorAddPost"
                element={
                  <AdRoute>
                    <InteriorAddPost />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/EditInteriotPost/:id"
                element={
                  <AdRoute>
                    <UpdateInteriorPost />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/usermanage"
                element={
                  <AdRoute>
                    <UserManagement />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/sellpostmanage"
                element={
                  <AdRoute>
                    <SellPostManagement />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/rentpostmanage"
                element={
                  <AdRoute>
                    <RentPostManagement />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/Group-Building-Manage"
                element={
                  <AdRoute>
                    <GroupBuildingManage />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/Group-Building-Desired-Request"
                element={
                  <AdRoute>
                    <GroupBuildingDesiredRequest />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/Group-Building-Edit/:id"
                element={
                  <AdRoute>
                    <UpdateGroupBuildingPost />
                  </AdRoute>
                }
              />

              <Route
                path="/admin/Set-Group-Building-Post"
                element={
                  <AdRoute>
                    <SetGroupBuildingPost />
                  </AdRoute>
                }
              />

              <Route
                path="admin/noticeboard"
                element={
                  <AdRoute>
                    <NoticeBoard />
                  </AdRoute>
                }
              />
              <Route
                path="admin/SiteSettings"
                element={
                  <AdRoute>
                    <SiteSettings />
                  </AdRoute>
                }
              />
            </Routes>
          </div>
        </UserProvider>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
